<template>
	<div>
	<a-row type="flex" justify="center" :gutter="24">
        <a-col :span="24" :md="14" class="mb-20">
		    <a-card :bordered="false" class="header-solid h-full card-profile-information" :bodyStyle="{paddingTop: 0, paddingBottom: '16px' }" :headStyle="{paddingRight: 0,}">
				<template v-if="!fetchInfo && !_.isEmpty(info)">
					<a-descriptions title="Thông tin" :column="1" class="mt-15">
						<a-descriptions-item label="Mã hóa đơn">
							#{{ info.code }}
						</a-descriptions-item>
						<a-descriptions-item label="Tài nguyên">
							{{ _.get(info, 'data.resources_label') }}
						</a-descriptions-item>
						<a-descriptions-item label="Kỳ hạn">
							{{ `${_.get(info, 'data.interval_label')} (${info.start_time} - ${info.end_time})` }}
						</a-descriptions-item>
						<a-descriptions-item label="Hạn thanh toán">
							{{ info.payment_expired }}
						</a-descriptions-item>
						<a-descriptions-item label="Ngày tạo">
							{{ info.created_at }}
						</a-descriptions-item>
					</a-descriptions>
					<hr class="my-15">
					<a-descriptions title="Số tiền" :column="1">
						<a-descriptions-item label="Tổng tiền">
							{{ currency(info.price) }} VNĐ
						</a-descriptions-item>
						<a-descriptions-item label="Chiết khấu">
							{{ currency(info.discount) }} VNĐ
						</a-descriptions-item>
						<a-descriptions-item label="Cần thanh toán">
							<h5 class="font-semibold text-primary">{{ currency(info.payment_price) }} VNĐ</h5>
						</a-descriptions-item>
					</a-descriptions>
					<a-row type="flex" justify="center" :gutter="24" class="mt-10 mb-30">
						<a-col :span="18" class="text-center">
							<a-input-group compact class="mb-20">
							<a-input
								v-model:value="discount"
								class="input-code"
								placeholder="Mã giảm giá"
							/>
							<a-button type="primary" @click="getDiscountMoney">Áp dụng</a-button>
							</a-input-group>
							<a-button v-if="wallet.price >= info.payment_price"
								@click="payment"
								:loading="loading"
								class="fill-success bg-success text-white"
							>Thanh toán hóa đơn
							</a-button>
							<a-button v-else
								@click="modal = true"
								class="fill-success bg-success text-white"
							>Nạp tiền
							</a-button>
						</a-col>
					</a-row>
				</template>
				<a-spin class="mt-25" v-else tip="Loading..."></a-spin>
			</a-card>
        </a-col>
    </a-row>
    <modal-qrcode :visible="modal"
		@close="modal = false"/>
	</div>
</template>

<script>

	import BillService from "@/services/bill"
	import getWallet from "@/services/user/wallet"
	import {currency} from '@/helper'
	import {ORDER_TYPE_INSTANCE,ORDER_RESIZE_SERVER,ORDER_TYPE_VOLUME,ORDER_RESIZE_VOLUME,ORDER_TYPE_SNAPSHOT,ORDER_TYPE_IP,ORDER_TYPE_FLOATING_IP} from '@/constant'
	import { notification } from 'ant-design-vue'
	import ModalQrcode from "@/components/Modal/ModalQrcode"

	export default ({
		beforeRouteLeave (to, from, next) {
		    clearInterval(this.interval)
		    next()
		},
		async created(){
			BillService.info(this.$route.params.id)
			.then(res => {
				if (this._.isObject(res) && res.status == 1) {
					this.fetchInfo = false
					this.info = this._.get(res, 'data', {})
					this.interval = setInterval(this.getWallet,10000)
					this.getWallet()
				} else {
					this.$router.push({name: '404'})
				}
			})
		},
		data() {
			return {
				fetchInfo: true,
				info: {},
				currency,
				discount : null,
				loading: false,
				modal : false,
				wallet : {},
				interval : 0,
			}
		},
		components: {
			ModalQrcode,
		},
		methods: {
			payment() {
				let formData = new FormData();
				formData.append('discount', this.discount || '');
				this.loading = true
				BillService.payment(this.$route.params.id,formData)
				.then(res => {
					if (this._.isObject(res) && res.status == 1) {
                        notification.success({
                            message: 'Thanh toán hóa đơn thành công'
                        })

						// Tùy theo loại đơn mà trả về module tương ứng
						switch (Number(this._.get(this.info, 'order.type'))) {
							case ORDER_TYPE_INSTANCE:
							case ORDER_RESIZE_SERVER:
								this.$router.push({name: 'servers'})
							break;
							case ORDER_TYPE_IP:
								this.$router.push({name: 'server.detail', params: {id: this._.get(this.info, 'order.meta_data.server_id')}, query:{action: 'ips'}})
							break;
							case ORDER_TYPE_VOLUME:
							case ORDER_RESIZE_VOLUME:
								this.$router.push({name: 'volumes'})
							break;
							case ORDER_TYPE_SNAPSHOT:
								this.$router.push({name: 'snapshots'})
							break;
							case ORDER_TYPE_FLOATING_IP:
								this.$router.push({name: 'floatingIP'})
							break;
							default:
								this.$router.push({name: 'billing'})
						}
                    } else {
                        notification.error({
                            message: res.message
                        })
                    }
					this.loading = false
				})
			},
			getDiscountMoney(){
				if(this.info.discount){
					return notification.error({
                        message: "Không thể sử dụng mã giảm giá !!"
                    })
				}
				this.loading = true
				BillService.info(this.$route.params.id,this.discount)
				.then(res => {
					this.loading = false
					if (this._.isObject(res) && res.status == 1) {
						this.fetchInfo = false
						this.info = this._.get(res, 'data', {})
					}else{
						notification.error({
                            message: res.message
                        })
					}
				})
			},
			async getWallet(){
				let wallet = await getWallet()
				if(wallet){
					this.wallet = wallet
				}
			}
		}
	})

</script>
<style lang="scss">
	.input-code{
		width: calc(100% - 200px) !important;
	}
	@media only screen and (max-width: 768px) {
		.input-code{
			width: calc(100% - 100px) !important;
		}
	}
</style>